'use client';

import { AuthProvider } from './auth-provider';
import UrqlProvider from './urql-client';

export default function CustomProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <AuthProvider>
      <UrqlProvider>{children}</UrqlProvider>
    </AuthProvider>
  );
}
